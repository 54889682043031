.back-decoration {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 450px;
  left: 50%;
  transform: translateX(-50%);

  .stripe-set {
    flex-basis: 50%;
  }

  .stripe + .stripe {
    margin-top: 30px;
  }

  .stripe {
    height: 80px;

    &.pink {
      background-color: #EB9DB4;
    }
    &.orange {
      background-color: #EBB168;
    }
    &.yellow {
      background-color: #EAD26B;
    }
    &.green {
      background-color: #45B49F;
    }
    &.blue {
      background-color: #83C8EF;
    }
    &.purple {
      background-color: #9690C5;
    }
  }
}

@media (max-width: 890px) {
  .back-decoration {
    display: none;
  }
}
