.list-links {
  p {
    font-size: 20px;
  }
  ul {
    list-style: none;
  }

  li + li {
    margin-top: 15px;
  }

  a {
    font-weight: 500;
    text-decoration: none;
    color: #fff;
  }
}

@media (max-width: 700px) {
  .list-links {
    text-align: center;
  }
}
