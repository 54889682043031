.button,
a.button-like-link {
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #e2910f;
  color: #fff;
  padding: 10px 35px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  border-radius: 10px;

  img {
    height: 23px;
    margin-right: 20px;
  }

  &.big {
    font-size: 18px;
    padding: 20px 0;
    width: 30%;
    max-width: 385px;
  }
}

@media (max-width: 700px) {
  .home .sign-up .console-decoration .button.big {
    font-size: 14px;
    padding: 10px 0;
  }
}
