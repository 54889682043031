.container.layout {
  background-color: #111217;
}

.main.layout {
  padding: 40px 40px 60px;
}

@media (max-width: 890px) {
  .main.layout {
    padding: 40px 20px 60px;
  }
}
