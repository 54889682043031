.container {
  position: relative;
  min-height: 100vh;
}

.main {
  position: relative;
  z-index: 1;
  background-color: #262626;
  width: 80%;
  max-width: 1280px;
  margin: 40px auto 60px;
  border-radius: 100px;
  text-align: center;
}


@media (max-width: 1100px) {
  .main {
    width: 90%;
  }
}

@media (max-width: 890px) {
  .main {
    width: 100%;
  }
}
