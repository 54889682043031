.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .section {
    width: 100%;
  }

  .block {
    margin-bottom: 40px;
  }

  .hero {
    .hero-logo {
      width: 100%;
      max-width: 800px;
      margin-bottom: 30px;
    }
    .hero-text {
      width: 100%;
      max-width: 475px;
      margin-bottom: 20px;
    }
    .hero-subtitle {
      font-size: 20px;
    }
    p {
      font-size: 18px;
      font-weight: 700;
    }

    .banner video,
    .banner img {
      width: 90%;
      max-width: 1050px;
      border: 10px solid #e2910f;
      border-radius: 35px;
    }
  }

  .sign-up {
    margin-bottom: 120px;
    width: calc(100% + 80px);

    .console-decoration {
      position: relative;
    }
    .console-decoration img {
      width: 100%;
    }
    .console-decoration button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, calc(-50% + 10px));
      padding: 28px 0;
      border-radius: 30px;
      font-size: 20px;
      letter-spacing: 0.3px;
    }
  }

  .image-cards {
    margin-bottom: 100px;
  }

  .ways-to-win {
    margin-bottom: 25px;

    p + p {
      margin-top: 1px;
    }
  }
  .join {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-bottom: 50px;

    .overview-l img {
      width: 220px;
      height: 220px;
    }

    .overview-r {
      text-align: left;
      flex-basis: 35%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
    .overview-r img {
      max-width: 100%;
    }
  }

  .overview {
    margin-bottom: 80px;
    display: flex;
    justify-content: center;
    gap: 60px;

    .overview-l img {
      max-width: 184px;
      width: 100%;
    }

    .overview-r {
      text-align: left;
      flex-basis: 30%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
    .overview-r img {
      max-width: 162px;
    }

    .overview-buttons-wrapper {
      display: flex;
      gap: 15px;
    }
  }
}

@media (max-width: 1570px) {
  .home {
    .overview {
      .overview-r {
        flex-basis: 32%;
      }
    }
  }
}

@media (max-width: 1480px) {
  .home {
    .overview {
      .overview-r {
        flex-basis: 35%;
      }
    }
  }
}

@media (max-width: 1360px) {
  .home {
    .overview {
      .overview-r {
        flex-basis: 40%;
      }
    }
  }
}

@media (max-width: 890px) {
  .home {
    .sign-up {
      width: calc(100% + 40px);
    }
  }
}

@media (max-width: 700px) {
  .home {
    .divider {
      margin-bottom: 70px;
    }

    .hero {
      .banner img {
        border: 5px solid #e2910f;
      }
    }

    .sign-up {
      button {
        font-size: 12px;
        padding: 15px 0;
        width: 120px;
      }
    }

    .image-cards {
      margin-bottom: 70px;
    }

    .ways-to-win {
      .title-content img {
        height: 60px;
      }
    }

    .prizes {
      .title-content img {
        height: 60px;
      }
    }

    .overview {
      margin-bottom: 40px;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;

      .overview-r {
        align-items: center;
        text-align: center;
        gap: 40px;
      }
    }
  }
}

@media (max-width: 450px) {
  .home {
    .overview {
      .overview-buttons-wrapper {
        flex-direction: column;
      }
    }
  }
}
