* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  //font-family: 'Press Start 2P', sans-serif;
}

body {
  overflow-x: hidden;
  color: #fff;
}

p {
  font-weight: 600;
}

.divider {
  margin-bottom: 100px;
  width: 80%;
  height: 22px;
  background-color: #1D1D1D;
  border-radius: 30px;
}

.divider-middle {
  margin-bottom: 75px;
}

.divider-small {
  margin-bottom: 55px;
}

