.title {
  display: flex;
  justify-content: center;
  margin: 0 auto 30px;
  gap: 25px;

  .title-decoration {
    width: 13%;
  }

  .title-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
}

.title.hero {
  justify-content: space-between;
  padding: 0 30px;

  .title-decoration {
    max-width: 94px;
  }
}

.title.prizes {

  .title-decoration {
    max-width: 80px;
  }
  .title-content img {
    height: 80px;
  }
}

.title.ways-to-win {
  .title-decoration {
    max-width: 74px;
  }
  .title-content img {
    height: 90px;
  }
}

@media (max-width: 700px) {
  .title {
    width: 90%;
    .title-decoration.hide-mobile {
      display: none;
    }
  }
}
