
.nav {
  width: 100%;
  display: flex;
  align-items: center;

  .nav-header {
    flex: 0.95;
    padding-left: 1rem;
  }
  .nav-title {
    font-size: 22px;
    color: #fff;
  }
  .nav-list {
    display: flex;
    align-items: center;
    gap: 30px;
  }
  .nav-list li {
    display: inline-block;
    list-style-type: none;
  }
  .nav-list a {
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
  }
  #nav-check {
    display: none;
  }
}

@media (max-width: 700px) {
  .nav {
    .nav-logo {
      width: 100px;
    }
    .nav-btn {
      margin-left: auto;
    }
    .nav-btn-label {
      display: inline-block;
      width: 50px;
      height: 50px;
      padding: 13px;
    }
    span {
      display: block;
      width: 25px;
      height: 10px;
      border-top: 2px solid #eee;
    }

    .nav-list {
      position: absolute;
      z-index: 2;
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: #212528;
      height: 0;
      transition: all 0.2s;
      top: 70px;
      left: 0;
      overflow: hidden;
    }
    #nav-check:checked ~ .nav-list {
      height: fit-content;
      padding: 30px;
      text-align: center;
    }
  }
}
