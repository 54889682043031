.leader-board {
  font-family: "PressStart2P";
  padding: 40px 0;
  border-radius: 60px;
  background-color: #262626;
  text-transform: uppercase;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 40px;
  }
  .title-img {
    width: 80px;
    filter: brightness(0) invert(1);
  }
  .title-txt {
    font-family: "PressStart2P";
    font-size: 40px;
    color: #AFE1FF;
  }

  .teams {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 60px;
  }
  .team-vs {
    font-size: 40px;
  }
  .team {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    flex-basis: 40%;
    .team-name {
      font-size: 40px;
    }
    .team-logo {

    }
    .team-score-title {
      font-size: 24px;
      color: #AFE1FF;
    }
    .team-score {
      font-size: 40px;
    }
  }

  .team.left {
    .team-name {
      color: #EB487E;
    }
  }
  .team.right {
    .team-name {
      color: #FDC960;
    }
  }

  .board {
    width: 80%;
    margin: auto;

    .with-label {
      display: flex;
      justify-content: center;
      gap: 20px;
    }
    .label {
      display: inline-block;
      background-color: #67938B;
      border-radius: 50px;
      color: #fff;
      font-size: 13px;
      padding: 6px 25px;
    }

    .board-row {
      display: grid;
      grid-template-columns: calc(100% / 8) calc(100% / 8 * 2) calc(100% / 8 * 3) calc(100% / 8 * 2);
      //grid-template-columns: calc(100% / 4) calc(100% / 4) calc(100% / 4) calc(100% / 4);
      //grid-gap: 10px;
    }

    .board-row p {
      font-size: 24px;
      padding: 10px 0;
    }

    .board-header p {
      color: #AFE1FF;
    }
    .board-row .record-id {
      text-transform: lowercase;
    }
    .board-row:nth-child(2) .record-id {
      color: #FFC216;
    }
    .board-row:nth-child(3) .record-id {
      color: #45B49F;
    }
    .board-row:nth-child(4) .record-id {
      color: #EB9DB4;
    }

    .record-team-name.left {
      color: #EB487E;
    }
    .record-team-name.right {
      color: #FDC960;
    }
  }

  .leader-board-button {
    margin: 60px 0 30px;

    button {
      margin: auto;
    }
  }
}


