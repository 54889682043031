.header.landing {
  background-color: #212528;
  width: 100%;
  height: 92px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  border-radius: 0px 0px 30px 30px;
}

@media (max-width: 700px) {
  .header.landing {
    padding: 0 20px;
  }
}
