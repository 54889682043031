footer {
  background-color: #212528;
}

.footer {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  padding: 80px 0;
  margin: 0 auto;

  .socials-container1 {
    //flex-basis: 30%;
  }

  .socials-container1 .logo {
    display: block;
    width: 180px;
    margin-bottom: 30px;
  }

  .socials-container1 .socials {
    width: fit-content;
    display: flex;
    gap: 30px;
    background-color: #131316;
    border-radius: 13px;
    padding: 20px 30px;
  }

  p {
    margin-bottom: 15px;
  }
}

@media (max-width: 1500px) {
  .footer {
    padding: 80px 40px;
  }
}

@media (max-width: 700px) {
  .footer {
    flex-direction: column-reverse;
    gap: 40px;
    padding: 40px 0;

    .socials-container1 .logo {
      width: 140px;
      margin: 0 auto 30px;
    }
  }
}
