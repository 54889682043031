.tabs {
  .menu {
    margin: auto;
    max-width: 700px;
    width: 100%;
    background-color: #1D1D1D;
    border-radius: 33px;
    display: flex;
    justify-content: space-between;
  }

  .item {
    cursor: pointer;
    padding: 18px 0;
    color: #7986A7;
    font-size: 16px;
    font-weight: 800;
    flex-basis: 50%;
  }

  .active.item {
    color: #fff;
    background-color: #E2910F;
    border-radius: 28px;
  }

  .tab-text-wrapper {
    display: flex;
    justify-content: center;
  }

  .tab-text {
    max-width: 740px;
    margin: 30px 0;
  }

  .tab-img {
    width: 100%;
    max-width: 796px;
  }

}

@media (max-width: 700px) {
  .tabs {
    .menu {
      flex-direction: column;
      max-width: 350px;
    }
    .tab-text {
      margin: 20px 0;
    }
  }
}



