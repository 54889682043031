.cards {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin: auto;
  max-width: 780px;

  .card {
    width: 142px;

    img {
      width: 100%;
      margin-bottom: 5px;
    }

    p {
      font-size: 16px;
      font-weight: 700;
    }
  }
}

@media (max-width: 700px) {
  .cards {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
}
