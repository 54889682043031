.header.wallet {
  background-color: #131316;
  width: 100%;
  height: 68px;
  display: flex;
  align-items: center;
  gap: 60px;
  padding: 0 50px;

  .logo {
    width: 120px;
    img {
      width: 100%;
    }
  }

  .menu {
    height: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
  }

  ul {
    display: flex;
  }

  li {
    height: 100%;
    display: flex;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  .left-menu {
    align-items: center;
    gap: 30px;
    a {
      color: #A5B2BB;
      display: flex;
      align-items: center;
      height: 100%;
      position: relative;
    }
    .active::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 5px;
      background-color: #21A6EE;
      border-radius: 5px;
    }
    .active img {
      filter: brightness(0) invert(1);
    }
  }

  .right-menu {
    gap: 10px;
  }

  .menu-elem {
    background-color: #1A1E25;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 115px;
    height: 44px;
    font-size: 13px;
    border-radius: 8px;
  }

  .menu-elem.icon-only {
    width: 44px;
  }




}
